import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import useCommon from '@/views/organization/useCommon'
import realmConnection from '@/views/habit/realm'

export default function useConfirmationPlanningList() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { handleError } = useCommon()
  const { invokeFunction, updateItem } = realmConnection()
  const { client = null, role = null } = store.state?.userStore?.userData
  const confirmationPlannings = ref([])
  const collection = 'confirmation_planning'

  // Table Headers
  const tableColumns = computed(() => {
    return [
      { key: 'roleName.name', label: i18n.t('message.tableHeader.role'), sortable: true },
      { key: 'processName.name', label: i18n.t('message.tableHeader.process'), sortable: true },
      { key: 'confirmationsPerYear', label: i18n.t('message.tableHeader.confirmationsPerYear'), sortable: true },
      { key: 'Actions', label: i18n.t('message.tableHeader.actions') },
    ]
  })
  const totalConfirmationPlannings = ref(0)
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 15, 20, 25, 30, 40, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('roleName.name')
  const isSortDirDesc = ref(false)
  const isLoading = ref(true)
  const processFilter = ref([]);
  const roleFilter = ref([]);

  const fetchConfirmationPlannings = async () => {
    isLoading.value = true

    try {
      const input = {
        limit: perPage.value,
        total: currentPage.value === 1,
        offset: (currentPage.value * perPage.value) - perPage.value,
        search: searchQuery.value,
        sortBy: sortBy.value,
        sortOrder: isSortDirDesc.value ? -1 : 1,
        processes: processFilter.value,
        roles: roleFilter.value,
        client_id: (role !== 'admin') ? client.$oid : null,
      }
    
      const items = await invokeFunction({ name: 'confirmationPlanningQueryWithOffset', arg: input })

      confirmationPlannings.value = items?.confirmationPlannings || []
      if (currentPage.value === 1) totalConfirmationPlannings.value = items?.totalRecords || 0
    } catch (error) {
      console.log(error)
      handleError({ error, defaultMessage: i18n.t('message.err_confirmation_planning') })
    } finally {
      isLoading.value = false
    }
  }

  const deleteConfirmationPlanning = async (id) => {
    try {
      const query = { _id: id }
      const action = { $set: { deleted: true } }

      await updateItem({ collection, query, action })

      fetchConfirmationPlannings()
      showSuccessMessage(i18n.t('message.confirmation_planning_remove'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.confirmation_planning_remove_error'))
    }
  }

  const showingMessage = computed(() => {
    const from = ((currentPage.value * perPage.value) - perPage.value) + (confirmationPlannings.value.length ? 1 : 0)
    const to = confirmationPlannings.value.length + ((currentPage.value * perPage.value) - perPage.value)

    return i18n.tc('message.paginationText', 0, { from: from, to: to, total: totalConfirmationPlannings.value })
  })

  const updateFilter = (data) => {
    processFilter.value = data.processFilter
    roleFilter.value = data.roleFilter

    fetchConfirmationPlannings()
  }

  watch([currentPage, perPage], () => {
    fetchConfirmationPlannings()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchConfirmationPlannings()
  })

  return {
    fetchConfirmationPlannings,
    tableColumns,
    perPage,
    currentPage,
    perPageOptions,
    totalConfirmationPlannings,
    showingMessage,
    searchQuery,
    sortBy,
    isSortDirDesc,
    confirmationPlannings,
    deleteConfirmationPlanning,
    isLoading,
    processFilter,
    roleFilter,
    updateFilter
  }
}
